.body {
    width: 90%;
    margin-left: 5%;
}

.title {
    text-align: left;
    font-size: 30px;
    font-weight: bold;
}

.inputbox{
    background-color: #F0F0F0;
    width: 50%;
    height: 40px;
    border-radius: 15px;
}

.img_search {
    width: 20px;
    margin-right: 5px;
}

.input {
    border-width: 0;
    outline: none;
    width: 90%;
    height: 40px;
    background-color:transparent;
}

.profiles{
    display: flex;
    width: 90%;
    margin-top: 20px;
}


.std{
    background-color: #F0F0F0;
    width: 200px;
    height: 250px;
    border-radius: 20px;
    margin-right: 20px;
}

.std_image {
    width: 100px;
    height: 100px;
    margin-top:15px;
    border-radius: 80px;
    object-fit: cover;
}

.std_name {
    margin:0;
    font-weight: bold;
}

.std_school{
    margin-top: 0;
}

.std_tel{
    margin-top: 10px;
    margin-bottom: 0;
}

.std_birth{
    margin-top: 5px;
}