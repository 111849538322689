
.topbar {
    color: black;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.logo p {
    margin: 0;
    font-size: 40px;
    font-family: Pretendard, sans-serif;
    font-weight: 900;
}

.navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.navbar li {
    margin-left: 20px;
}

.navbar a {
    color: black;
    text-decoration: none;
    font-size: 18px;
}

.navbar a:hover {
    text-decoration: underline;
}