*{
    font-family: Pretendard, sans-serif;
    font-weight: 500;
}

.profile-body {
    width: 90%;
    margin-left: 5%;
}

.title {
    text-align: left;
    font-size: 30px;
    font-weight: bold;
}

.profile {
    display: flex;
    justify-content: left;
}

.profile-image {
    width: 25%;
    object-fit: cover;
}

.info {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    text-align: left;
    margin-left: 20px;
}

.name {
    font-size: 25px;
}

.school {
    font-size: 15px;
    margin: 0;
    color: gray;
}

.tel {
    margin-top: 5px;
    margin-bottom: 0;

}

.career {
    flex-grow: 1;
    background-color: #F5F5F7;
    border-radius: 15px;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    min-height: 100px;
    padding-top:5px;
}

.career p {
    margin-left: 20px;
    margin-bottom: 0;
}